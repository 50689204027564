import { graphql, useStaticQuery } from 'gatsby';

const useLogos = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulLindseyCreditsLogos {
        lindseyLogoImages {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `);

  return data.contentfulLindseyCreditsLogos.lindseyLogoImages.map((logo) => ({
    images: logo.fluid,
    title: logo.title,
  }));
};

export default useLogos;
