import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image from 'gatsby-image';

export const MultiCarousel = (props) => {
  return (
    <Carousel
      partialVisible
      swipeable={true}
      draggable={false}
      arrows={false}
      showDots={false}
      responsive={props.responsive}
      ssr={props.ssr} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
      itemClass="image-item"
    >
      {props.logos.map((logo) => (
        <div key={logo.title}>
          <Image
            style={{ width: '100%', height: '100%' }}
            fluid={logo.images}
            alt={logo.title}
            key={logo.title}
          />
        </div>
      ))}
    </Carousel>
  );
};
