/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import useLogos from '../hooks/use-logos';
import { MultiCarousel } from '../components/multiCarousel';
import { MultiCarouselApple } from '../components/multiCarouselApple';

export default () => {
  const logos = useLogos();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [isIOS, setIsIOS] = useState(true);
  const [isSafari, setIsSafari] = useState(true);

  useEffect(() => {
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;

    if (isSafari === true) {
      setIsSafari(true);
    } else {
      setIsSafari(false);
    }

    function iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }
    let appleTest = iOS();
    // let appleTest =
    //   /iPad|iPhone|iPod/.test(navigator.platform) ||
    //   (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (appleTest === true) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  return (
    <>
      <div className="carouselWrapper">
        <div className="carouselEdgeLeft"></div>
        {isIOS || isSafari ? (
          <MultiCarouselApple />
        ) : (
          <MultiCarousel
            className="multiCarousel"
            logos={logos}
            responsive={responsive}
            ssr={true}
          />
        )}
        <div className="carouselEdgeRight"></div>
      </div>

      <div className="creditsPage">Credits</div>
      <div className="creditsPage__Overview">
        <div className="creditsPage_left"></div>
        <div className="creditsPage_right"></div>
        <div className="creditsPage__body-text">
          <div>
            <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>
              2022
            </strong>
            <br />
            Secrets of the Royal Gardens{' '}
            <span style={{ color: '#45a29e' }}>– Channel 4</span>
            <br />
            Commonwealth Games 2022{' '}
            <span style={{ color: '#45a29e' }}>– BBC Radio 5 live</span>
            <br />
            Volkswagen UK{' '}
            <span style={{ color: '#45a29e' }}>– Haymarket Studio</span>
            <br />
            Courtside{' '}
            <span style={{ color: '#45a29e' }}>
              – England Netball Official Podcast
            </span>
            <br />
            Netball Lead Commentator{' '}
            <span style={{ color: '#45a29e' }}> – Sky Sports</span>
            <br />
            Thundercast
            <span style={{ color: '#45a29e' }}>
              {' '}
              – Superleague Netball Podcast
            </span>
          </div>
          <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>2021</strong>
          <br />
          Netball Lead Commentator{' '}
          <span style={{ color: '#45a29e' }}>– Sky Sports</span>
          <br />
          The Royal Conservationist{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          Costing the Earth{' '}
          <span style={{ color: '#45a29e' }}> – BBC Radio 4</span>
          <br />
          Self Isolating Bird Club
          <span style={{ color: '#45a29e' }}> – Facebook</span>
          <br />
          Pick of the Week
          <span style={{ color: '#45a29e' }}> – BBC Radio 4</span>
          <br />
          International Women&apos;s Day
          <span style={{ color: '#45a29e' }}> – MCR City of Trees</span>
          <br />
          ThunderCast
          <span style={{ color: '#45a29e' }}> – Netball Podcast</span>
        </div>
        <div className="creditsPage__body-text1">
          <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>2020</strong>
          <br />
          Wild Animal Rescue{' '}
          <span style={{ color: '#45a29e' }}>– Channel 5</span>
          <br />
          Out of the Wood{' '}
          <span style={{ color: '#45a29e' }}> – BBC Radio 4</span>
          <br />
          #WildMorningswithChrisPackham
          <span style={{ color: '#45a29e' }}> – YouTube</span>
          <br />
          Pick of the Week
          <span style={{ color: '#45a29e' }}> – BBC Radio 4</span>
          <br />
          The Art of Raising a Child
          <span style={{ color: '#45a29e' }}> – BBC Radio 4</span>
          <br />
          Netball Nations Cup
          <span style={{ color: '#45a29e' }}> – BBC Radio 5 live</span>
          <br />
          ThunderCast
          <span style={{ color: '#45a29e' }}> – Netball Podcast</span>
        </div>
        <div className="creditsPage__body-text2">
          <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>2019</strong>
          <br />
          Animals After Dark{' '}
          <span style={{ color: '#45a29e' }}>– Channel 5</span>
          <br />
          Springtime on the Farm{' '}
          <span style={{ color: '#45a29e' }}>– Channel 5</span>
          <br />
          Netball World Cup
          <span style={{ color: '#45a29e' }}> – BBC R5Live/World Service</span>
          <br />
          Pick of the Week{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          Netball Quad Series{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 5 live</span>
          <br />
          An Evening with Chris Packham{' '}
          <span style={{ color: '#45a29e' }}>– Niddfest</span>
        </div>
        <div className="creditsPage__body-text3">
          <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>2018</strong>
          <br />
          Springtime on the Farm{' '}
          <span style={{ color: '#45a29e' }}>– Channel 5</span>
          <br />
          Winterwatch Digital Takeover{' '}
          <span style={{ color: '#45a29e' }}>– @BBC</span>
          <br />
          LUSH Live with Chris Packham{' '}
          <span style={{ color: '#45a29e' }}>– LUSH Player</span>
          <br />
          Costing the Earth{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          Host: Sustainability Season
          <span style={{ color: '#45a29e' }}> – BBC North</span>
          <br />
          Opening Night <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          Curious Creatures <span style={{ color: '#45a29e' }}>– BBC Two</span>
          <br />
          Slow Art <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          Tweet of the Day{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          Living World Series{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          Pick of the Week{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 4</span>
          <br />
          It’s Getting Hot in Here{' '}
          <span style={{ color: '#45a29e' }}>– CBBC Pilot</span>
        </div>
        <div className="creditsPage__body-text4">
          <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>2017</strong>
          <br />
          Springwatch Unsprung{' '}
          <span style={{ color: '#45a29e' }}>– BBC Two</span>
          <br />
          Curious Creatures <span style={{ color: '#45a29e' }}>– BBC Two</span>
          <br />
          Winterwatch Digital Takeover{' '}
          <span style={{ color: '#45a29e' }}>– @BBC</span>
          <br />
          Hay Festival <span style={{ color: '#45a29e' }}>– BBC Arts</span>
          <br />
          Edinburgh Fringe Festival{' '}
          <span style={{ color: '#45a29e' }}>– BBC Arts</span>
          <br />
          Contains Strong Language w/Jeremy Irons{' '}
          <span style={{ color: '#45a29e' }}>– R4</span>
          <br />
          #OperaPassion <span style={{ color: '#45a29e' }}>– BBC Arts</span>
          <br />
          Children in Need <span style={{ color: '#45a29e' }}>– @BBC</span>
          <br />
          Keynote Speaker{' '}
          <span style={{ color: '#45a29e' }}>– Yorkshire Wildlife Trust</span>
        </div>
        <div className="creditsPage__body-text5">
          <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>2016</strong>
          <br />
          Springwatch Unsprung{' '}
          <span style={{ color: '#45a29e' }}>– BBC Two</span>
          <br />
          Travel Presenter{' '}
          <span style={{ color: '#45a29e' }}>– BBC Radio 5 live</span>
          <br />
          Shakespeare Day Live{' '}
          <span style={{ color: '#45a29e' }}>– BBC Arts</span>
          <br />
          Edinburgh Fringe <span style={{ color: '#45a29e' }}>– BBC Arts</span>
          <br />
          #DreamLive at the Globe{' '}
          <span style={{ color: '#45a29e' }}>– BBC Arts</span>
          <br />
          Love to Read <span style={{ color: '#45a29e' }}>– @BBC</span>
        </div>
        <div className="creditsPage__body-text6">
          <strong style={{ fontSize: '2.5rem', color: '#1f2833' }}>
            2014/15
          </strong>
          <br />
          Big Blue UK <span style={{ color: '#45a29e' }}>– BBC One</span>
          <br />
          Autumnwatch Extra{' '}
          <span style={{ color: '#45a29e' }}>– BBC Red Button</span>
          <br />
          Winterwatch Unsprung{' '}
          <span style={{ color: '#45a29e' }}>– BBC Red Button</span>
          <br />
          Springwatch Extra{' '}
          <span style={{ color: '#45a29e' }}>– BBC Red Button</span>
          <br />
          Something to Think About{' '}
          <span style={{ color: '#45a29e' }}>– BBC School Radio</span>
          <br />
          Netball Superleague Reporter{' '}
          <span style={{ color: '#45a29e' }}>– Sky Sports</span>
          <br />
          <br />
        </div>
      </div>
    </>
  );
};
