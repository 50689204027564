import React, { useState } from 'react';
import Image from 'gatsby-image';
import useLogos from '../hooks/use-logos';

export const MultiCarouselApple = () => {
  const logos = useLogos();

  const [multiCarousel, setAMultiCarousel] = useState(logos[0].images);
  const [multiCarouselCounter, setMultiCarouselCounter] = useState(0);

  setTimeout(() => {
    if (multiCarouselCounter >= logos.length) {
      setMultiCarouselCounter(0);
    } else {
      setAMultiCarousel(logos[multiCarouselCounter].images);
      setMultiCarouselCounter(multiCarouselCounter + 1);
    }
  }, 2000);

  return (
    <div className="multiCarouselApple">
      <Image
        fluid={multiCarousel}
        style={{
          borderRadius: '1rem',
          boxShadow: '0.2rem 0.2rem 1.4rem #c5c6c7',
        }}
      ></Image>
    </div>
  );
};
